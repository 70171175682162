/*
  ____                _                                         _
 | __ )   __ _   ___ | | __ __ _  _ __  ___   _   _  _ __    __| |
 |  _ \  / _` | / __|| |/ // _` || '__|/ _ \ | | | || '_ \  / _` |
 | |_) || (_| || (__ |   <| (_| || |  | (_) || |_| || | | || (_| |
 |____/  \__,_| \___||_|\_\\__, ||_|   \___/  \__,_||_| |_| \__,_|
                           |___/
*/

.NewsGridLayer {
    grid-template-areas: ". . . ." ". . NavBar ." ". . . ." ". TitleText TitleText ." ". SubTitleText SubTitleText ." ". Arrow Arrow ." ". . . ." "NewsBody NewsBody NewsBody NewsBody";
}

.NewsAccentLayer {
    top: 0;
    height: 100%;
    width: 100vw;
    background: #CA9502;
    opacity: .1;
    z-index: 1;
}

/*
   ____               _                _
  / ___| ___   _ __  | |_  ___  _ __  | |_
 | |    / _ \ | '_ \ | __|/ _ \| '_ \ | __|
 | |___| (_) || | | || |_|  __/| | | || |_
  \____|\___/ |_| |_| \__|\___||_| |_| \__|

*/

.NewsBodyPage {
    grid-area: NewsBody;
    width: 100%;
    height: 100%;
    background: rgba(255, 185, 20, 0.5);
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-rows: 10% 75% 15%;
    grid-template-areas: ". . ." ". NewsArticles ." ". . .";
    font-family: Roboto-regular, sans-serif;
    position: relative;
}

.NewsArticles {
    position: relative;
    grid-area: NewsArticles;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 6vh 4.5vh;
    grid-template-areas: "Article_1 Article_2" "Article_3 Article_4";
}

.ArticleCover {
    position: absolute;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /*border: 1px solid rgba(255, 255, 255, 0);*/
    transition: .5s;
}

.Articles {
    border-radius: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
    transition: .5s;
}

.Articles:hover {
    width: Calc(100% + 30px);
    max-height: Calc(100% + 30px);
    box-shadow: 0 6px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

.Articles:active {
    box-shadow: 0 6px 8px 8px rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

.Articles:hover .ArticleCover {
    /*border: 2px solid rgba(253, 189, 13, 0.47);*/
    /*box-shadow: 0 6px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);*/
    filter: blur(4px);
}

.Articles:active .ArticleCover {
    /*border: 2px solid rgba(253, 189, 13, 0.47);*/
    width: Calc(100% + 30px);
    max-height: Calc(100% + 30px);
    /*box-shadow: 0 6px 8px 8px rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);*/
}

.ArticleTitle {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #cc9c1f;
    text-align: center;
    background: rgba(255, 255, 255, 0.78);
    background-image: linear-gradient(to bottom right, rgba(253, 211, 93, 0.31), rgba(255, 255, 255, 0.78));
    transition: .5s;
    opacity: 0;
    z-index: 10;
}

.Articles:hover .ArticleTitle {
    opacity: 1;
}

.ArticleTitleText {
    position: absolute;
    font-family: Roboto-900, sans-serif;
    font-size: 3.5vw;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Article_1 {
    grid-area: Article_1;
    /*background: red;*/
}

.Article_2 {
    grid-area: Article_2;
    /*background: blue;*/
}

.Article_3 {
    grid-area: Article_3;
    /*background: green;*/
}

.Article_4 {
    grid-area: Article_4;
    /*background: orange;*/
}


/*
  _           _    _
 | |     ___ | |_ | |_  ___  _ __  ___
 | |    / _ \| __|| __|/ _ \| '__|/ __|
 | |___|  __/| |_ | |_|  __/| |   \__ \
 |_____|\___| \__| \__|\___||_|   |___/

 */
.NewsGridLayer > .PageTitle {
    height: 63%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.NewsGridLayer > .PageSubTitle {
    color: rgba(255, 255, 255, 0.9);
    /*border: 3px solid rgba(255, 255, 255, 0.8);*/
    background: rgba(202, 149, 2, .9);
    font-size: 2.7vw;
}

.NewsGridLayer > .PageTitle > #news_letters {
    fill: #CA9502;
}


.NewsGridLayer > .PageTitle:hover #news_letters {
    /*font-family: Roboto-regular, sans-serif;*/
    /*fill: rgba(255, 255, 255, 0.42);*/
    stroke: #CA9502;
    stroke-width: .2vw;
    stroke-miterlimit: 10;
    overflow: visible;
    animation: letters_transition_color 2s ease forwards;
    animation-delay: 1s;
    /*animation-play-state: running;*/
}

@keyframes letters_transition_color {
    to {
        fill: rgba(0, 21, 49, 0.1);
        stroke: white;
    }
}

#news_letters path:nth-child(1) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1263;
}

#news_letters path:nth-child(2) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1076;
}

#news_letters path:nth-child(3) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1633;
}

#news_letters path:nth-child(4) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1119;
}

@media only screen and (max-width: 800px) {
    .NewsBodyPage {
        grid-template-columns: 5% 90% 5%;
        grid-template-rows: 10% 75% 15%;
    }

    .NewsGridLayer {
        width: 100vw;
        height: 150vh;
        grid-template-columns: 6% 88% 6%;
        /*grid-template-rows: 20% 6% 10% 3.33% 13.33% 14% 33%;*/
        grid-template-rows: 26% 7% 13% 3% 13% 5% 33%;
        gap: 1px 1px;
        grid-template-areas: ". . ." "TitleText TitleText TitleText" ". SubTitleText ."  ". . ." ". NavBar ." ". . ." "NewsBody NewsBody NewsBody";
    }

    .NewsGridLayer > .PageTitle {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    .NewsGridLayer > .PageSubTitle {
        width: fit-content;
        height: fit-content;
        font-size: 2.4vh;
    }

    .ArticleCover {
        height: 100%;
        max-width: 100%;
    }

    .NewsGridLayer > .PageSubTitle {
        width: fit-content;
        height: fit-content;
        font-size: 2.4vh;
    }
}